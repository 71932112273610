<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" v-model="valid" >
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          <v-card-title>
            <span class="">{{
              (editedIndex === -1 ? $t("add") : $t("edit")) +
              " " +
              $t("supervisors.supervisor")
            }}</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.name"
                  :rules="[rulesWithLength.required]"
                  :label="$t('supervisors.name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  outlined
                  hide-details
                  persistent-hint
                  :items="directorates"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.directorate_id"
                  :rules="[rules.required]"
                  :label="$t('directorates.directorate')"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  outlined
                  hide-details
                  persistent-hint
                  :items="specializations"
                  item-text="text"
                  item-value="value"
                  v-model="editedItem.occupational_specialization"
                  :rules="[rules.required]"
                  :label="$t('supervisors.specialization')"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-combobox
                  outlined
                  hide-details
                  v-model="editedItem.study_subjects"
                  :rules="[rulesWithLength.required]"
                  :items="subjects"
                  :label="$t('supervisors.subject')"
                ></v-combobox>
              </v-col>
              <v-col cols="12" md="1">
                <v-switch
                  hide-details
                  persistent-hint
                  v-model="editedItem.active"
                  :label="$t('supervisors.active')"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="white--text"
            @click="save"
            :disabled="!valid || submitLoading"
            :loading="submitLoading"
            :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "onSave",
    "onClose",
    "dialog",
    "editedItem",
    "editedIndex",
    "resetValidation",
  ],
  data() {
    return {
      valid: true,
      submitLoading: false,
      directorates: [],
      subjects: [
        this.$t("subjects.english"),
        this.$t("subjects.arabic"),
        this.$t("subjects.math"),
        this.$t("subjects.history"),
        this.$t("subjects.islamic"),
        this.$t("subjects.biology"),
      ],
      specializations: [
        {
          text: this.$t("specializations.educational"),
          value: "educational",
        },
        {
          text: this.$t("specializations.specialty"),
          value: "specialty",
        },
      ],
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
      rulesWithLength: {
        required: (value) =>
          (value != null && value.length < 255) ||
          this.$t("ThisFieldIsRequired"),
      },
    };
  },
  created() {
    this.$axios
      .get("GetUserDirectorates",{noToast: true})
      .then((response) => {
        this.directorates = response.data.data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  watch: {
    resetValidation() {
      this.resetForm();
    },
  },
  computed: {},
  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    save() {
      var val = this.$refs.form.validate();
      if (val) {
        this.submitLoading = true;
        if (this.editedIndex > -1) {
          if (
            this.editedItem.active == undefined ||
            this.editedItem.active == null
          ) {
            this.editedItem.active = 0;
          } else if (
            this.editedItem.active == true ||
            this.editedItem.active == 1
          ) {
            this.editedItem.active = 1;
          } else {
            this.editedItem.active = 0;
          }
          this.$axios
            .post(
              "UpdateSupervisor/" + this.editedItem.id,
              this.editedItem
            )
            .then((response) => {
              if (response.data.status == "Successful") {
                this.onClose();
                this.$emit("refreshTable");
              }
            })
              .finally(() => {
                this.$emit("refreshTable");
                this.submitLoading = false;
              });
        } else {
          if (
            this.editedItem.active == undefined ||
            this.editedItem.active == null
          ) {
            this.editedItem.active = 0;
          } else if (
            this.editedItem.active == true ||
            this.editedItem.active == 1
          ) {
            this.editedItem.active = 1;
          } else {
            this.editedItem.active = 0;
          }
          this.$axios
            .post("AddSupervisor", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.onClose();
                this.$emit("refreshTable");
              }
            })
              .finally(() => {
                this.$emit("refreshTable");
                this.submitLoading = false;
              });
        }
      }
    },
  },
};
</script>
