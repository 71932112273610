<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          outlined
          dense
          :label="$t('search')"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters.isInRole(29)"
          color="primary"
          dark
          class="mb-2"
          @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>
      <data-table-component
        :headers="headers"
        :items="supervisors"
        :search="search"
        :loading="loading"
      />
    </v-card>

    <Dialog
      :onClose="close"
      :dialog="dialog"
      :editedItem="editedItem"
      :editedIndex="editedIndex"
      :valid="valid"
      :resetValidation="resetValidation"
      @refreshTable="refreshTable"
    />
    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import Dialog from "./Dialog.vue";
import DataTableComponent from "../../components/DataTableComponent.vue";
export default {
  components: { DataTableComponent, ConfirmDialog, Dialog },
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      supervisors: [],
      editedItem: {
        id: 0,
        name: null,
        study_subjects: null,
        directorate_id: null,
        active: false,
        occupational_specialization: null,
      },
      specializations: [
        {
          text: this.$t("specializations.educational"),
          value: "educational",
        },
        {
          text: this.$t("specializations.specialty"),
          value: "specialty",
        },
      ],
      defaultItem: {
        id: 0,
        name: null,
        study_subjects: null,
        directorate_id: null,
        active: false,
        occupational_specialization: null,
      },
      resetValidation: 0,
    };
  },
  computed: {
    headers() {
      var list = [
        { text: this.$t("supervisors.name"), value: "name" },
        { text: this.$t("supervisors.subject"), value: "study_subjects" },
        { text: this.$t("supervisors.directorate"), value: "directorate" },
        {
          text: this.$t("supervisors.specialization"),
          value: "occupational_specialization",
          type: "select",
          items: this.specializations,
        },
        {
          text: this.$t("supervisors.active"),
          value: "active",
          type: "checkbox",
          readonly: true,
        },
      ];
      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };
      if (this.$store.getters.isInRole(1)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(1)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },
  created() {
    if (!this.$store.getters.isInRole(28)) {
      this.$router.push({ name: "notauthorize" });
    }
    this.loading = true;
    this.refreshTable();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.supervisors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.supervisors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      var deleteItem = this.supervisors[this.editedIndex];
      this.$axios
        .delete("DeleteSupervisor/" + deleteItem.id)
        .then(() => {
          this.refreshTable();
        });

      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    refreshTable() {
      this.$axios
        .get("GetSupervisors",{ failureToast: true})
        .then((response) => {
          this.supervisors = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
